import { MouseEventHandler } from 'react'

import { body, title } from '@genie-fintech/ui/style/typography'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { toClassNames, trueOrUndefined } from '@genie-fintech/ui/functions'
import { Icon } from '@genie-fintech/ui/icons'

import useIsMounted from '$browser/useIsMounted'

import ExtraIcon from '$icons/ExtraIcon'
import { ready } from '$layouts/styles.css'

import { Timezones } from '$store/settings'

import {
  backbuttonStyle,
  doubleConfirmMainContainer,
  selectedTimezoneContainer,
  flexDirectionColumn,
  warningIconContainer,
  doubleConfirmFooterContainer
} from './styles.css'

const { colors } = themeVars

export const Confirm = ({
  selectedTimezone,
  clearSelectedTimezone,
  onCancel,
  onConfirm,
  loading
}: {
  selectedTimezone?: Timezones[number]
  clearSelectedTimezone: VoidFunction
  onCancel: VoidFunction
  onConfirm: (timezone: string) => void
  loading?: boolean
}) => {
  const isMounted = useIsMounted()

  const onBack: MouseEventHandler = e => {
    e.stopPropagation()
    clearSelectedTimezone()
  }

  return (
    <article
      className={toClassNames(flexDirectionColumn, ready)}
      style={{ flex: 1 }}
      data-ready={trueOrUndefined(isMounted)}
    >
      <main className={doubleConfirmMainContainer}>
        <div>
          <button
            className={backbuttonStyle}
            onClick={onBack}
            disabled={loading}
          >
            <Icon namespace="Backward" width={16} />
            Back
          </button>
        </div>

        <div>
          <span className={warningIconContainer}>
            <ExtraIcon name="alert-triangle" />
          </span>
        </div>

        <div className={flexDirectionColumn} style={{ gap: 4 }}>
          <h4 className={title.four}>You are about to change the timezone!</h4>

          <p className={body.three} style={{ color: colors.neutral[70] }}>
            You are changing the timezone to...
          </p>
        </div>

        <div className={selectedTimezoneContainer}>
          {selectedTimezone?.value}
        </div>
      </main>

      <footer className={doubleConfirmFooterContainer}>
        <Button
          onClick={onCancel}
          disabled={loading}
          styleVariants={{
            type: 'outlined',
            kind: 'neutral',
            size: 'small'
          }}
        >
          Cancel
        </Button>

        <Button
          onClick={() => onConfirm(selectedTimezone?.key ?? '')}
          styleVariants={{ size: 'small' }}
          disabled={loading}
        >
          {loading && <Spinner />}
          Confirm
        </Button>
      </footer>
    </article>
  )
}

export default Confirm
