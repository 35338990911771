import { useRef, useState } from 'react'
import { useBoolean, useClickAway, useRequest } from 'ahooks'

import { GENERAL_DURATION } from '@genie-fintech/ui/constants/transitions'
import { Portal } from '@genie-fintech/ui/components'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { Icon } from '@genie-fintech/ui/icons'

import useSignal from '$actions/useSignal'

import api from '$model/api'
import { profile } from '$store/profile'
import { refreshStates } from '$store/profiles'
import { timezones, Timezones } from '$store/settings'
import lazyToast from '$services/lazyToast'
import { errorMessageResolver } from '$services/api/common'
import { wait } from '$app/utilities'

import Choice from './Choice'
import Confirm from './Confirm'

import { iconStyle, overlay, overlayContent, container } from './styles.css'

type Timezone = Timezones[number]

const request = (data: { timezone: string }) => {
  const { putTimezone } = api.value.auth

  return putTimezone(data)
}

export const TimezoneSetting = () => {
  const profileStore = useSignal(profile)
  const { timezone } = { ...profileStore?.data }

  const [open, { setTrue: openModal, setFalse: closeModal }] = useBoolean()

  const overlayContentRef = useRef(null)
  const selectInputRef = useRef(null)

  const [selectedTimezone, setSelectedTimezone] = useState<Timezone>()

  const timezone_list = useSignal(timezones)

  const { loading, runAsync } = useRequest(request, { manual: true })

  const onCancel = () => setSelectedTimezone(undefined)
  const onClose = () => {
    closeModal()
    wait(GENERAL_DURATION).then(onCancel)
  }

  const onConfirm = (timezone: string) => {
    lazyToast(runAsync({ timezone }), {
      loading: 'Updating..',
      error: errorMessageResolver,
      success: 'Timezone update successful.'
    }).then(() => {
      refreshStates()
      onClose()
    })
  }

  const state = (() => {
    if (!selectedTimezone) return 'choice'

    return selectedTimezone.key !== timezone ? 'confirm' : 'choice'
  })()

  useClickAway(onClose, [overlayContentRef, selectInputRef])

  return (
    <>
      <Portal>
        <section
          role="dialog"
          aria-hidden={trueOrUndefined(!open)}
          className={overlay({ open })}
        >
          <article ref={overlayContentRef} className={overlayContent}>
            <div hidden={trueOrUndefined(state !== 'choice')}>
              <Choice
                currentTimezone={timezone}
                timezones={timezone_list}
                onChangeTimezone={setSelectedTimezone}
              />
            </div>

            <div hidden={trueOrUndefined(state !== 'confirm')}>
              <Confirm
                clearSelectedTimezone={onCancel}
                selectedTimezone={selectedTimezone}
                onCancel={onCancel}
                onConfirm={onConfirm}
                loading={loading}
              />
            </div>
          </article>
        </section>
      </Portal>

      <article className={container}>
        <BaseText
          label="Preferred Timezone"
          required
          affix={{ post: <Icon namespace="Down" className={iconStyle} /> }}
          fullWidth
          elementControlProps={{ style: { cursor: 'pointer' } }}
          inputProps={{
            readOnly: true,
            style: { pointerEvents: 'none' },
            onFocus: openModal,
            value: timezone_list.find(t => t.key === timezone)?.value ?? ''
          }}
          containerRef={selectInputRef}
        />
      </article>
    </>
  )
}

export default TimezoneSetting
