import { MouseEventHandler, useState } from 'react'
import { useDebounce } from 'ahooks'

import { BaseText } from '@genie-fintech/ui/components/fields'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { Icon } from '@genie-fintech/ui/icons'

import useIsMounted from '$browser/useIsMounted'

import { ready } from '$layouts/styles.css'

import { Timezones } from '$store/settings'

import {
  emptyData,
  iconStyle,
  optionContainer,
  optionItem,
  optionLabel,
  searchInputContainerStyle,
  searchInputStyle
} from './styles.css'

type Timezone = Timezones[number]

type ChoiceProps = {
  currentTimezone?: Timezone['key']
  timezones: Timezones
  onChangeTimezone: (value: Timezone) => void
}

export const Choice = ({
  currentTimezone,
  timezones,
  onChangeTimezone
}: ChoiceProps) => {
  const isMounted = useIsMounted()

  const [searchValue, setSearchValue] = useState('')

  const debouncedSearch = useDebounce(searchValue, { wait: 500 })

  const filteredZones = timezones.filter(t =>
    t.value.toLocaleLowerCase().includes(debouncedSearch.toLocaleLowerCase())
  )
  const options = filteredZones.map(({ key, ...rest }) => ({
    key,
    isCurrent: key == currentTimezone,
    ...rest
  }))

  const onClearSearch: MouseEventHandler = e => {
    e.stopPropagation()
    setSearchValue('')
  }

  const onOptionItemClick = (option: (typeof options)[number]) => {
    onChangeTimezone(option)
  }

  return (
    <article className={ready} data-ready={trueOrUndefined(isMounted)}>
      <div className={searchInputContainerStyle}>
        <BaseText
          affix={{
            pre: <Icon namespace="Search" className={iconStyle} />,
            post: searchValue && (
              <Icon
                namespace="Cross"
                className={iconStyle}
                onClick={onClearSearch}
                style={{ cursor: 'pointer' }}
              />
            )
          }}
          elementControlProps={{ className: searchInputStyle }}
          inputProps={{
            value: searchValue,
            onChange: e => setSearchValue(e.currentTarget.value),
            autoFocus: true
          }}
        />
      </div>

      <div className={optionContainer}>
        {!options.length && <div className={emptyData}>No Data</div>}

        {options.map(option => (
          <button
            key={option.key}
            type="button"
            className={optionItem}
            disabled={option.isCurrent}
            onClick={() => onOptionItemClick(option)}
          >
            <span className={optionLabel}>{option.value}</span>

            {option.isCurrent && (
              <Icon
                namespace="Check"
                color="primary.default"
                className={iconStyle}
              />
            )}
          </button>
        ))}
      </div>
    </article>
  )
}

export default Choice
