import { FC, useEffect } from 'react'
import { z } from 'zod'
import { useController, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import useSignal from '$actions/useSignal'
import queryParams from '$store/queryParams'

import Phone from '$hook-fields/Phone'

import { TypeSubmitForm } from '$hook-forms/types'
import {
  requiredString,
  requiredTrimmedString
} from '$hook-forms/general-validators'

import { submit } from '$forms/common/style.css'
import { allowedWhatsappCountries, callingCodes } from '$constants/country-data'

import { container, description, otpOptionContainer } from './styles.css'
import { Checkbox } from '@genie-fintech/ui/components'

import WhatsappLogo from '$icons/WhatsappLogo'

const Schema = z.object({
  phone_code: requiredString,
  phone_no: requiredTrimmedString.min(5, {
    message: 'At least 5 digits required'
  }),
  is_whatsapp: z.boolean()
})

const resolver = zodResolver(Schema)

type TFields = z.infer<typeof Schema>

const checkAllowedWhatsappCountryByPhoneCode = (phone_code: string) => {
  return allowedWhatsappCountries.some(wc => {
    const selectedCountry = callingCodes.find(d => d.code === +phone_code)?.id
    return wc === selectedCountry
  })
}

const initialPhoneCode = `${callingCodes[0].code}`

const defaultValues: TFields = {
  phone_code: initialPhoneCode,
  phone_no: '',
  is_whatsapp: checkAllowedWhatsappCountryByPhoneCode(initialPhoneCode)
}

export type MobileOTPRequestFormFields = TFields

export type MobileOTPRequestFormProps = TypeSubmitForm<TFields>

export const MobileOTPRequestForm: FC<MobileOTPRequestFormProps> = ({
  onSuccess,
  disabled
}) => {
  const {
    params: { auth_country, auth_phone_number }
  } = useSignal(queryParams)

  const phone_code = callingCodes.find(({ id }) => id === auth_country)?.code

  const { control, reset, watch, handleSubmit, formState, setValue } =
    useForm<TFields>({
      resolver,
      defaultValues,
      mode: 'all'
    })

  const isInvalid = disabled || !!Object.keys(formState.errors).length

  const canUseWhatsapp = checkAllowedWhatsappCountryByPhoneCode(
    watch('phone_code')
  )

  useEffect(() => {
    setValue('is_whatsapp', canUseWhatsapp)
  }, [setValue, canUseWhatsapp])

  useEffect(() => {
    if (!phone_code) return

    setValue('phone_code', `${phone_code}`)
  }, [setValue, phone_code])

  useEffect(() => {
    if (!auth_phone_number) return

    setValue('phone_no', `${auth_phone_number}`)
  }, [setValue, auth_phone_number])

  const { field } = useController({ name: 'is_whatsapp', control })

  return (
    <form
      className={container}
      onSubmit={handleSubmit((...handler) => onSuccess(reset, ...handler))}
      noValidate
      autoComplete="off"
    >
      <Phone
        control={control}
        callingCodes={callingCodes}
        name="phone_no"
        name2="phone_code"
        label="Phone Number"
        required
        inputProps={{ placeholder: 'XXXX XXXX XXXX' }}
        disabled={disabled}
      />

      <button type="submit" className={submit} disabled={isInvalid}>
        Get OTP
      </button>

      {canUseWhatsapp && (
        <article className={otpOptionContainer}>
          <article style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
            <Checkbox
              label="Get Code by Whatsapp"
              boxProps={{
                checked: field.value,
                onCheckedChange: field.onChange
              }}
            />
            <WhatsappLogo />
          </article>

          <p className={description}>
            If you have whatsapp with this phone number.
          </p>
        </article>
      )}
    </form>
  )
}

export default MobileOTPRequestForm
