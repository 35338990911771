import { FC } from 'react'
import { useBoolean, useRequest } from 'ahooks'

import { Button, Portal } from '@genie-fintech/ui/components'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { Icon } from '@genie-fintech/ui/icons'

import useInert from '$browser/useInert'
import useTimer from '$actions/useTimer'

import { refreshStates } from '$store/profiles'
import api from '$model/api'
import { Profile } from '$services/api/auth'
import lazyToast from '$services/lazyToast'
import { errorMessageResolver, errorStatusResolver } from '$services/api/common'

import { description, header, title } from '$blocks/common/auth.css'
import ChangeEmailRequestForm from '$hook-forms/ChangeEmailRequestForm'

import OTP, { OTPProps } from '../OTP'

import {
  backButton,
  container,
  overlay,
  overlayContent,
  value
} from './styles.css'

const CODE_LENGTH = 6

const request = (data: { email: string }) => {
  const { changeEmailRequest } = api.value.auth
  return changeEmailRequest(data)
}

const confirm = (data: { code: string }) => {
  const { changeEmailConfirm } = api.value.auth
  return changeEmailConfirm(data)
}

export type ChangeEmailBlockProps = Partial<Pick<Profile, 'email'>>

export const ChangeEmailBlock: FC<ChangeEmailBlockProps> = ({ email }) => {
  const [isModalOpen, { setTrue: openModal, setFalse: closeModal }] =
    useBoolean()

  const modalRef = useInert(!open)

  const otpTimer = useTimer(60 * 1000)

  const [isConfirmState, { setTrue: setConfirm, setFalse: cancelConfirm }] =
    useBoolean()

  const changeEmailReq = useRequest(request, { manual: true })

  const confirmEmailReq = useRequest(confirm, { manual: true })

  const requests = [changeEmailReq, confirmEmailReq]

  const onReset = () => {
    requests.forEach(({ cancel, mutate }) => {
      cancel()
      mutate()
    })
    cancelConfirm()
  }

  const onCancel = () => {
    onReset()
    closeModal()
  }

  const onUnauthorizedHandler = (err: unknown) => {
    if (errorStatusResolver(err) === 403) {
      onReset()
      return 'Session Timeout. Please try again!'
    }

    return errorMessageResolver(err)
  }

  const onContinue = (payload: { email: string }) => {
    const { cancel, runAsync } = changeEmailReq

    cancel()
    otpTimer.reset()

    lazyToast(runAsync(payload), {
      loading: 'Requesting..',
      error: errorMessageResolver,
      success: 'OTP requested.'
    }).then(setConfirm)
  }

  const onOTPResend: OTPProps['onResend'] = () => {
    const { refreshAsync, cancel } = changeEmailReq

    cancel()

    lazyToast(refreshAsync(), {
      error: onUnauthorizedHandler,
      loading: 'Resending..',
      success: 'OTP requested'
    }).finally(() => {
      otpTimer.reset()
    })
  }

  const onOTPFilled: OTPProps['onFilled'] = async (code, resetInput) => {
    const { runAsync, cancel } = confirmEmailReq

    cancel()

    await lazyToast(runAsync({ code }), {
      error: onUnauthorizedHandler,
      loading: 'Verifying..',
      success: 'Successfully email changed!'
    })

    resetInput()

    refreshStates()

    onCancel()
  }

  return (
    <>
      <article className={container}>
        <span className={value}>{email}</span>

        <Button
          styleVariants={{ kind: 'neutral', size: 'small', type: 'outlined' }}
          onClick={openModal}
        >
          {!email && <Icon namespace="Add" />}
          {email ? 'Change' : 'Add Email'}
        </Button>
      </article>

      <Portal>
        <section
          ref={modalRef}
          role="dialog"
          aria-hidden={trueOrUndefined(!isModalOpen)}
          className={overlay({ open: isModalOpen })}
        >
          <article className={overlayContent}>
            {isConfirmState && (
              <button
                type="button"
                onClick={onReset}
                className={backButton}
                disabled={confirmEmailReq.loading}
              >
                <Icon namespace="Backward" /> Back
              </button>
            )}

            <main>
              {!isConfirmState && (
                <>
                  <header className={header}>
                    <p className={title}>
                      {email ? 'Enter New Email' : 'Add Email'}
                    </p>
                    <p className={description}>
                      {email
                        ? 'Enter new email to continue the changes.'
                        : 'Enter email to continue the process.'}
                    </p>
                  </header>

                  <ChangeEmailRequestForm
                    email={email}
                    loading={changeEmailReq.loading}
                    onCancel={onCancel}
                    onContinue={onContinue}
                  />
                </>
              )}

              {isConfirmState && (
                <OTP
                  title="Enter Code"
                  description={`We’ve sent ${CODE_LENGTH} digit verification code to new email.`}
                  disabled={confirmEmailReq.loading}
                  length={CODE_LENGTH}
                  onResend={onOTPResend}
                  onFilled={onOTPFilled}
                  timerActive={otpTimer.active}
                  timerSeconds={otpTimer.seconds}
                />
              )}
            </main>
          </article>
        </section>
      </Portal>
    </>
  )
}

export default ChangeEmailBlock
