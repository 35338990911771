import { FC } from 'react'
import { mapValues, toNumber } from 'lodash-es'
import { useBoolean, useRequest } from 'ahooks'

import { Button, Portal } from '@genie-fintech/ui/components'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { Icon } from '@genie-fintech/ui/icons'

import useInert from '$browser/useInert'
import useTimer from '$actions/useTimer'

import api from '$model/api'
import { refreshStates } from '$store/profiles'
import { Profile } from '$services/api/auth'
import lazyToast from '$services/lazyToast'
import { errorMessageResolver, errorStatusResolver } from '$services/api/common'

import { description, header, title } from '$blocks/common/auth.css'
import ChangePhoneRequestForm from '$hook-forms/ChangePhoneRequestForm'

import OTP, { OTPProps } from '../OTP'

import {
  backButton,
  container,
  overlay,
  overlayContent,
  value
} from './styles.css'

const CODE_LENGTH = 6

const request = (data: { phone_code: number; phone_no: number }) => {
  const { changePhoneRequest } = api.value.auth
  return changePhoneRequest(data)
}

const confirm = (data: { code: string }) => {
  const { changePhoneConfirm } = api.value.auth
  return changePhoneConfirm(data)
}

export type ChangePhoneBlockProps = Partial<
  Pick<Profile, 'phone_code' | 'phone_no'>
>

export const ChangePhoneBlock: FC<ChangePhoneBlockProps> = ({
  phone_code,
  phone_no
}) => {
  const [isModalOpen, { setTrue: openModal, setFalse: closeModal }] =
    useBoolean()

  const modalRef = useInert(!open)

  const otpTimer = useTimer(60 * 1000)

  const [isConfirmState, { setTrue: setConfirm, setFalse: cancelConfirm }] =
    useBoolean()

  const changePhoneReq = useRequest(request, { manual: true })

  const confirmPhoneReq = useRequest(confirm, { manual: true })

  const requests = [changePhoneReq, confirmPhoneReq]

  const onReset = () => {
    requests.forEach(({ cancel, mutate }) => {
      cancel()
      mutate()
    })
    cancelConfirm()
  }

  const onCancel = () => {
    onReset()
    closeModal()
  }

  const onUnauthorizedHandler = (err: unknown) => {
    if (errorStatusResolver(err) === 403) {
      onReset()
      return 'Session Timeout. Please try again!'
    }

    return errorMessageResolver(err)
  }

  const onContinue = ({
    phone_code,
    phone_no
  }: {
    phone_code: string
    phone_no: string
  }) => {
    const { cancel, runAsync } = changePhoneReq

    cancel()
    otpTimer.reset()

    lazyToast(runAsync(mapValues({ phone_code, phone_no }, toNumber)), {
      loading: 'Requesting..',
      error: errorMessageResolver,
      success: 'OTP requested.'
    }).then(setConfirm)
  }

  const onOTPResend: OTPProps['onResend'] = () => {
    const { refreshAsync, cancel } = changePhoneReq

    cancel()

    lazyToast(refreshAsync(), {
      error: onUnauthorizedHandler,
      loading: 'Resending..',
      success: 'OTP requested'
    }).finally(() => {
      otpTimer.reset()
    })
  }

  const onOTPFilled: OTPProps['onFilled'] = async (code, resetInput) => {
    const { runAsync, cancel } = confirmPhoneReq

    cancel()

    await lazyToast(runAsync({ code }), {
      error: onUnauthorizedHandler,
      loading: 'Verifying..',
      success: 'Successfully phone changed!'
    })

    resetInput()

    refreshStates()

    onCancel()
  }

  const hasPhone = !!phone_code && !!phone_no

  return (
    <>
      <article className={container}>
        <span className={value}>{hasPhone && `${phone_code} ${phone_no}`}</span>

        <Button
          styleVariants={{ kind: 'neutral', size: 'small', type: 'outlined' }}
          onClick={openModal}
        >
          {!hasPhone && <Icon namespace="Add" />}
          {hasPhone ? 'Change' : 'Add Phone Number'}
        </Button>
      </article>

      <Portal>
        <section
          ref={modalRef}
          role="dialog"
          aria-hidden={trueOrUndefined(!isModalOpen)}
          className={overlay({ open: isModalOpen })}
        >
          <article className={overlayContent}>
            {isConfirmState && (
              <button
                type="button"
                onClick={onReset}
                className={backButton}
                disabled={confirmPhoneReq.loading}
              >
                <Icon namespace="Backward" /> Back
              </button>
            )}

            <main>
              {!isConfirmState && (
                <>
                  <header className={header}>
                    <p className={title}>
                      {hasPhone ? 'Enter New Phone Number' : 'Add Phone Number'}
                    </p>
                    <p className={description}>
                      {hasPhone
                        ? 'Enter new phone number to continue the changes.'
                        : 'Enter phone number to continue the process.'}
                    </p>
                  </header>

                  <ChangePhoneRequestForm
                    current_phone_code={phone_code?.replaceAll('+', '')}
                    current_phone_no={phone_no}
                    loading={changePhoneReq.loading}
                    onCancel={onCancel}
                    onContinue={onContinue}
                  />
                </>
              )}

              {isConfirmState && (
                <OTP
                  title="Enter Code"
                  description={`We’ve sent ${CODE_LENGTH} digit verification code to new phone number.`}
                  disabled={confirmPhoneReq.loading}
                  length={CODE_LENGTH}
                  onResend={onOTPResend}
                  onFilled={onOTPFilled}
                  timerActive={otpTimer.active}
                  timerSeconds={otpTimer.seconds}
                />
              )}
            </main>
          </article>
        </section>
      </Portal>
    </>
  )
}

export default ChangePhoneBlock
