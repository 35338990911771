import { FC, useEffect } from 'react'
import { capitalize } from 'lodash-es'
import { format } from 'date-fns'

import { Spinner } from '@genie-fintech/ui/components'
import { trueOrUndefined } from '@genie-fintech/ui/functions'

import usePageClass from '$browser/usePageClass'
import useSignal from '$actions/useSignal'
import useRouter from '$actions/useRouter'

import PageHead from '$layouts/AuthenticatedLayout/PageHead'
import PageRegion from '$layouts/AuthenticatedLayout/PageRegion'
import PageContents from '$layouts/AuthenticatedLayout/PageContents'

import TimezoneSetting from '$blocks/TimezoneSetting'
import ChangeEmailBlock from '$blocks/ChangeEmailBlock'
import ChangePhoneBlock from '$blocks/ChangePhoneBlock'

import { profile } from '$store/profile'

import Details from './Details'

import { content, header as headerClassName } from './styles.css'

const pageName = 'ProfileDetail'

export const Apps: FC = () => {
  usePageClass({ name: pageName })

  const {
    queryParams: { open_change_password },
    redirectWithQueryParams
  } = useRouter()

  const profileStore = useSignal(profile)
  const { loading, data } = { ...profileStore }

  const { name, address, email, phone_code, phone_no, gender, date_of_birth } =
    { ...data }

  // Backward-compatibility for old web-auth sdk versions.
  useEffect(() => {
    if (!open_change_password) return

    redirectWithQueryParams('profilePassword')
  }, [open_change_password, redirectWithQueryParams])

  return (
    <>
      <PageHead
        title="User Details"
        description="Manage and review user accounts for efficient system administration."
      />

      <PageRegion>
        <PageContents
          title="Information"
          description="Some of the user informations can be editable."
          mainClassName={content}
          mainProps={{ 'data-loading': trueOrUndefined(loading) }}
        >
          {loading ? (
            <Spinner
              color="neutral.100"
              style={{
                alignSelf: 'center',
                justifySelf: 'center',
                marginLeft: 'auto',
                marginRight: 'auto'
              }}
            />
          ) : (
            <>
              <Details title="User Name" data-testid="profile-info-name">
                {name}
              </Details>

              <Details title="Date of Birth" data-testid="profile-info-dob">
                {date_of_birth ? format(date_of_birth, 'dd MMM yyyy') : '-'}
              </Details>

              <Details title="Gender" data-testid="profile-info-gender">
                {capitalize(gender) ?? '-'}
              </Details>

              <Details title="Address" data-testid="profile-info-address">
                {address ?? '-'}
              </Details>
            </>
          )}
        </PageContents>

        <PageContents
          title="Configure Timezone"
          description="The system will provide reports aligned with your selected timezone."
          headerClassName={headerClassName}
          nav={<TimezoneSetting />}
        />

        <PageContents
          title="Add/Edit Phone Number"
          description="You can update your phone number with anytime."
          headerClassName={headerClassName}
          nav={<ChangePhoneBlock phone_code={phone_code} phone_no={phone_no} />}
        />

        <PageContents
          title="Add/Edit Email Address"
          description="You can update your email with anytime."
          headerClassName={headerClassName}
          nav={<ChangeEmailBlock email={email} />}
        />
      </PageRegion>
    </>
  )
}

export default Apps
