import { useController, ControllerRenderProps } from 'react-hook-form'

import { resolveErrorMessage } from '@genie-fintech/ui/components/hook-fields/common/functions'

import { CallingCodes } from '$constants/country-data'

import {
  Phone as InputPhone,
  PhoneProps as InputPhoneProps
} from '$fields/Phone'
import { DualHookField } from '$hook-fields/types'

type InputProps = Required<InputPhoneProps>['inputProps']
type InheritedProps = Omit<
  InputPhoneProps,
  'callingCodeProps' | 'inputProps' | 'inputRef'
>

export type PhoneProps = InheritedProps & {
  callingCodes?: CallingCodes
  inputProps?: Omit<InputProps, keyof ControllerRenderProps>
}

export const Phone: DualHookField<PhoneProps> = ({
  name,
  name2,
  control,
  disabled,
  callingCodes = [],
  controllerOptions,
  ...fieldProps
}) => {
  const one = useController({
    name,
    control,
    disabled,
    ...controllerOptions
  })
  const two = useController({
    name: name2,
    control,
    disabled,
    ...controllerOptions
  })

  const { value = '', ref: inputRef, ...controlledFieldProps } = one.field

  const errorMessage = `${[
    resolveErrorMessage(one.fieldState.error),
    resolveErrorMessage(two.fieldState.error)
  ].filter(Boolean)}`
  const hasError = !!errorMessage

  const inputPhoneProps: InputPhoneProps = {
    callingCodes,
    disabled,
    error: hasError,
    message: errorMessage,
    ...fieldProps,
    inputRef,
    inputProps: {
      ...fieldProps.inputProps,
      ...controlledFieldProps,
      value
    },
    callingCodeProps: two.field
  }

  return <InputPhone {...inputPhoneProps} />
}

export default Phone
