import { RouteNames } from '$app/router'
import { authMethods, AuthMethods } from './auth'

export const VITE_DEPLOYMENT_TAG = import.meta.env.VITE_DEPLOYMENT_TAG

export const NAMESPACE = 'genie-auth-webview'

export const LOCAL_STORAGE_KEY_SEPARATOR = ':'

export const THEME_NAME = import.meta.env.VITE_THEME_NAME

export const DEFAULT_ROUTE: RouteNames = 'applications'

export const DEFAULT_AUTH_METHOD: AuthMethods = authMethods[0]

export const GENERAL_TIME_FORMAT_WITH_TIMEZONE = 'dd MMM yyyy, hh:mma z'
